*{
    margin: 0;
    padding: 0;
}

.headerContainer{
    background-color: rgba(0, 0, 0, 0.452);
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
}

.display{
    display: flex;
    align-items: center;
    column-gap: 20px;
    color: white;
}

.display img{
    width: 40px;
    height: 40px;
}

.btn-container{
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.btn-container .MuiSvgIcon-root{
    font-size: 20px;
    margin-right: 10px;
}

.bottom-bar{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    display: none;
    z-index: 999;
    margin-top: 100vh;
}

.bottom-bar button{
    flex-basis: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 187, 125);
    cursor: pointer;
}
.accountWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.account {
    line-height: 14px;
    color: #fff;
}
.chevron {
    margin: 0 10px;
    color: #fff;
}
.iconAccount {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width: 900px){
    .headerContainer{
        flex-direction: column;
    }
    .bottom-bar{
        display: flex;
    }
    .btn-container{
        display: none;
    }
}