.removeBtn{
    font-size: 22px;
    margin-right: 6px;
    color: grey;
    cursor: pointer;
    display: none !important;
}

.done-btn{
    outline: 0;
    border: 0;
    background-color: rgb(0, 187, 125);
    border-radius: 2px;
    color: white;
    padding: 6px 10px;
    font-size: 16px;
    margin-top: 8px;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.add-btn{
    width: 100%;
    padding: 10px 0;
    flex-grow: 1;
    margin-right: 15px;
    background-color: rgb(0, 187, 125);
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}
.delete-btn{
    width: 100%;
    padding: 10px 0;
    flex-grow: 1;
    margin-right: 15px;
    background-color: rgb(115, 117, 117);
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}

